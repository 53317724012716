.template {
  &__guest {
    min-height: 100vh;
    &--right {
      background-color: $primary;
      background-image: url("../Assests/right-side.svg");
      background-position: center 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &--left {
      min-height: 99vh;
      // overflow: auto;
      &_inner {
        max-width: 440px;
      }
    }
  }

  &__login {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    min-height: 100vh;
    &--content {
      padding: 40px 60px;
    }
    &--header {
      padding: 40px 60px;
    }
    &--sideMenu {
      padding: 20px 10px;
      .ant-menu-item {
        color: #657081 !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        padding: 15px 35px;
        height: auto;
        flex-direction: row;
        display: flex;
        align-items: center;
        svg:not(.no_fill) {
          fill: #657081;
        }
        &-selected {
          color: #fff !important;
          background: $primary;
          svg:not(.no_fill) {
            fill: #fff;
          }
          svg.strok_on_active {
            stroke: #fff;
          }
        }
      }
    }
    &--logo {
      width: 100%;
      height: 64px;
      background-image: url("../Assests/main-logo.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid #f4f4f4;
    }
    &--sider {
      width: 250px;
    }
  }
}

.ant-modal-content {
  border-radius: 0;
  .ant-modal-body {
    text-align: center;
    padding: 40px 0;
    .modal-icon {
      padding: 15px;
      background-color: #ffedea;
      margin: auto;
      border-radius: 24px;
      display: flex;
      min-width: 76px;
      min-height: 76px;
      flex-direction: column;
      justify-content: center;
      flex-wrap: nowrap;
      align-items: center;
    }
    .ant-modal-confirm-title {
      padding: 30px 0 15px 0px;
    }
    .ant-modal-confirm-content {
      text-align: center !important;
      margin: auto !important;
      max-width: none !important;
    }
    .ant-modal-confirm-btns {
      display: block;
      text-align: center !important;
      padding: 25px 0 5px 0;
      .ant-btn {
        font-size: 16px;
        height: 40px;
        border-radius: 0;
        max-width: 48%;
        margin-left: 1%;
        margin-right: 1%;
        &.ant-btn-primary {
          background-color: #f59315;
        }
        &.ant-btn-dangerous {
          background-color: #ff4b2d;
        }
      }
    }
  }
  .ant-modal-footer {
    display: flex;
  }
}
.custom-modal {
  .ant-modal-body {
    padding: 40px 0 10px 0;
  }
  .ant-modal-footer {
    padding-bottom: 30px;
  }
}
.ant-form-item-control-input-content {
  .ant-input-number-input {
    text-align: center;
    width: 56px !important;
  }
  .ant-input-number {
    width: 56px !important;
    margin-left: 65px;
    .ant-input-number-handler-wrap {
      position: static;
      opacity: 1;
      .ant-input-number-handler {
        position: absolute;
        left: -65px;
        width: 56px;
        right: auto;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
        height: auto;
        font-size: 20px;
        line-height: 2;
        border: 1px solid #d9d9d9;
        color: #000;
        &:hover {
          border: 1px solid #f59315;
          color: #000;
        }
        &.ant-input-number-handler-down {
          right: -65px;
          left: auto;
        }
      }
    }
  }
}

.ant-form-item.h-auto .ant-form-item-control-input {
  min-height: auto !important;
  height: auto !important;
}
.ant-form-item-explain-error {
  text-transform: lowercase;
}
.ant-form-item-explain-error:first-letter {
  text-transform: capitalize;
}

.ant-list-bordered {
  border: none !important;
  .ant-list-items {
    .ant-list-item {
      padding: 14px 24px;
      margin-bottom: 10px;
      border: 1px solid #f0f0f0;
    }
  }
}

.user-profile-edit-image {
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  &.in-header {
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 10px 0px #00000017;
    border: 1px solid #f9f9f9;
  }
}
.col-in-header {
  height: auto !important;
  line-height: normal;
}
.in-header-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0px 0px 10px 0px #00000017;
  border: 1px solid #f9f9f9;
  background: linear-gradient(0deg, #f9f9f9, #f9f9f9),
    linear-gradient(0deg, #ffffff, #ffffff);
}

// .user-profile-edit-avatar-in-header {
//   box-shadow: 0px 0px 10px 0px #00000017 !important;
//   background: linear-gradient(0deg, #f9f9f9, #f9f9f9),
//     linear-gradient(0deg, #ffffff, #ffffff) !important;
// }
.ant-empty-description {
  color: #000;
}

.ant-carousel {
  .slick-dots {
    li {
      button {
        width: 20px;
        height: 20px;
        font-size: 0;
        background: #030303;
        cursor: pointer;
        opacity: 1;
        border-radius: 50%;
      }
      &.slick-active {
        button {
          background: #cecece;
          opacity: 1;
        }
      }
    }
  }
  .slick-dots-bottom {
    bottom: -25px;
    height: 20px;
  }
}
.buttons-on-map {
  color: #f59315;
  background-color: #fbecd9;
  border-radius: 25px;
  &.active {
    color: #ffffff;
    background-color: #f59315;
  }
}
