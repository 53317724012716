$primary: #f59315;
$sizes: 12;
$componentPadding: 30px;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

* {
  padding: 0;
  margin: 0;
}
.mh-100 {
  min-height: 100% !important;
}
.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.bold {
  font-weight: 600;
}
.text-center {
  text-align: center;
}

.componentPadding {
  padding: $componentPadding;
}
.componentPadding-y {
  padding-block: $componentPadding;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-dark {
  color: #000;
}
.fw-bold {
  font-weight: 600;
}
@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m-#{$i} {
      margin: $margin;
    }
    .ml-#{$i} {
      margin-left: $margin;
    }
    .mr-#{$i} {
      margin-right: $margin;
    }
    .mt-#{$i} {
      margin-top: $margin;
    }
    .mb-#{$i} {
      margin-bottom: $margin;
    }
    .mx-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p-#{$i} {
      padding: $padding;
    }
    .pl-#{$i} {
      padding-left: $padding;
    }
    .pr-#{$i} {
      padding-right: $padding;
    }
    .pt-#{$i} {
      padding-top: $padding;
    }
    .pb-#{$i} {
      padding-bottom: $padding;
    }
    .px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;
