@import "./veriables.scss";
@import "./template.scss";
@import "./component.scss";
@import "./responsive.scss";

// .ant-col {
//   border: 1px dashed red;
// }
.ant-btn-dark {
  color: #fff;
  background-color: #242a31;
  box-shadow: 0 2px 0 rgb(255 185 5 / 10%);
  &.dark__icon {
    padding: 2px 15px 2px 2px;
    height: auto;
    border-radius: 10px;
    transition: 0.15s ease-in-out;
    font-size: 20px;
    line-height: 30px;
    .anticon {
      color: #242a31;
      padding: 16px;
      border-radius: 10px;
      transition: 0.15s ease-in-out;
    }
    span:not(.anticon) {
      position: relative;
      left: -12px;
      transform: translateX(-12px);
      transition: 0.2s ease-in-out;
    }
    &:hover {
      span:not(.anticon) {
        left: 0;
        transform: translateX(0);
      }
      .anticon {
        background-color: #454a50;
        color: #fff;
      }
    }
  }
}
.ant-btn {
  &.ico-button {
    padding: 5px;
    border-radius: 5px;
  }
}
.ant-list-grid .ant-col > {
  .ant-list-item:not(.coupan-item) {
    padding: 0px;
    > div {
      padding: 16px;
    }
  }
}
.coupan-item {
  padding: 0 !important;
  &__header {
    border: 2px dashed #f4f2f2;
    padding: 16px;
    transition: all 0.2s ease-in-out;
    img {
      transition: all 0.2s ease-in-out;
    }
  }
  &__validity {
    text-align: right;
    transition: all 0.2s ease-in-out;
    padding: 0 30px;
    margin: 0 auto;
    &--paragraph {
      padding: 10px;
      background-color: #f5f7fb;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      border: 2px dashed transparent;
      border-top: 0;
    }
  }
  &:hover {
    .coupan-item {
      &__header {
        border: 2px dashed $primary;
        img {
          transform: scale(1.1);
        }
      }
      &__validity {
        &--paragraph {
          border: 2px dashed $primary;
          border-top: 0;
        }
      }
    }
  }
}
.food-shadow-app-container {
  .ant-btn.account-settings-button {
    height: auto !important;
    padding: 15px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    img {
      display: inline-block;
      vertical-align: middle;
      transition: all 0.2s ease-in-out;
    }
    .ant-typography {
      transition: all 0.2s ease-in-out;
    }
    img.arrow {
      margin: 0 10px;
    }
    &:hover {
      border: 1px solid;
      img.arrow {
        transform: scale(1.1);
        margin: 0 5px;
      }
    }
  }
}
.button {
  transition: all 0.2s ease-in-out;
  .scale-animate {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    border: 1px solid $primary !important;
    .scale-animate {
      transform: scale(1.1);
    }
  }
}
