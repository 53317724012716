.shopping-list {
  background-image: url("../Assests/banner-bg.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  &__desc {
    max-width: 315px;
    display: block;
  }
  &__main {
    max-width: 370px;
    margin: auto;
  }
}
.bg-white {
  background-color: #fff;
}
